import React from "react";
// @ts-ignore
import Seo from "../utils/seo";

// @ts-ignore
import Layout from "../layouts";
import EmpowerLeadership from "../sections/EmpowerLeadership";
import AIxHW from "../sections/AIxHW";
import AICoach from "../sections/AICoach";
import AIinAction from "../sections/AIinAction";
import AIExp from "../sections/AIExperience";
import AIhow from "../sections/AIFeatures";
import AICoaching from "../sections/AICoaching";
import AIPersonalized from "../sections/AIPersonalizedAnimation";

const IndividualCoaching = () => {
  return (
    <>
      <Seo
        title="Coachello - Online Professional Coaching Platform"
        // image={seoImg}
        description="Discover our professional coaching platform. From group workshops to individual coaching, we are here to give you an amazing experience."
      />
      <Layout>
        <EmpowerLeadership />
        <AIxHW />
        <AICoach />
        <AIhow />
        <AIinAction />
        <AIPersonalized />
        <AIExp />
        <AICoaching />
      </Layout>
    </>
  );
};

export default IndividualCoaching;
